import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Klowydz_ex2PrjMx_bGHw71DQOe4qTinFQtrl2SVv_s from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/nuxt-umami/dist/runtime/plugin.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/.nuxt/pwa-icons-plugin.ts";
import echo_nfnQCajojU1EshRAYTuVVrBYUYjmZqE1Jp1VxyEUn7U from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/plugins/echo.js";
import globalMixin_WJZBEQjWKrqmrC5wsMFu_3iV_71DxiIw7A8rfV2yCQE from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/plugins/globalMixin.js";
import pinia_2z8Ip_jWBRJR11oARwTuXOuYmKme2JpGLevDuCPfHcc from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/plugins/pinia.js";
import ui_1ROpdlHvMwEuBmv33_5jcPPy1OlXGVQs4zVQ3phRIFU from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/plugins/ui.js";
import pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ from "/home/ploi/app.abcreche.com-deploy/app.abcreche.com/10042024_131856/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_Klowydz_ex2PrjMx_bGHw71DQOe4qTinFQtrl2SVv_s,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  echo_nfnQCajojU1EshRAYTuVVrBYUYjmZqE1Jp1VxyEUn7U,
  globalMixin_WJZBEQjWKrqmrC5wsMFu_3iV_71DxiIw7A8rfV2yCQE,
  pinia_2z8Ip_jWBRJR11oARwTuXOuYmKme2JpGLevDuCPfHcc,
  ui_1ROpdlHvMwEuBmv33_5jcPPy1OlXGVQs4zVQ3phRIFU,
  pwa_client_FQM5r5C96_zrgtYZL3Jbj_skHFBc_M2JlWere1x75dQ
]